export const config = {
  development: {
    storage: 'gs://storage.staging.alinosense.com',

    api: {
      auth: 'https://europe-west1-at-development-302017.cloudfunctions.net/user',
      gateway: 'https://frontend-api-f6j2xuvk3a-ew.a.run.app/api',
    },

    publicStorage: 'public.staging.alinosense.com',

    firebase: {
      apiKey: 'AIzaSyBh4jMb5c-5mqUj2S4vTcbVMGSmPzEr5b4',
      authDomain: 'at-development-302017.firebaseapp.com',
      projectId: 'at-development-302017',
      storageBucket: 'at-development-302017.appspot.com',
      messagingSenderId: '943374270276',
      appId: '1:943374270276:web:e00bbc9fa01e4f1bc7a111',
      measurementId: 'G-6REPR563HX',
    },

    dynamicLinks: {
      shareDataUrl: 'https://dev.alinocam.com',
      shareShortUrl: 'https://dev.alinocam.com/link',
    },
  },

  production: {
    storage: 'gs://storage.alinocam.com',
    api: {
      auth: 'https://europe-west1-alinocam-production.cloudfunctions.net/user',
      gateway: 'https://frontend-api-oyzsbqskmq-ew.a.run.app/api', //"https://frontend-api-oyzsbqskmq-ew.a.run.app/api",
    },

    publicStorage: 'public.alinocam.com',

    firebase: {
      apiKey: 'AIzaSyDqfZjSOkzJncA9Vv4wt3tI25P-m7sxGBc',
      authDomain: 'alinocam-production.firebaseapp.com',
      projectId: 'alinocam-production',
      storageBucket: 'alinocam-production.appspot.com',
      messagingSenderId: '465837658632',
      appId: '1:465837658632:web:db91dd75077728bb27f7bd',
      measurementId: 'G-L3VCYET15J',
    },
    dynamicLinks: {
      shareDataUrl: 'https://app.alinocam.com',
      shareShortUrl: 'https://app.alinocam.com/link',
    },
  },

  staging: {
    storage: 'gs://storage.staging.alinocam.com',
    api: {
      auth: 'https://europe-west1-alinocam-staging.cloudfunctions.net/user',
      gateway: 'https://frontend-api-ewbxzigfaa-ew.a.run.app/api', //"https://frontend-api-oyzsbqskmq-ew.a.run.app/api",
    },

    publicStorage: 'public.staging.alinocam.com',

    firebase: {
      apiKey: "AIzaSyC9eEuyp50T9gs0fkEPDLyD2ABhwVVojLY",    
      authDomain: "alinocam-staging-9d4ee.firebaseapp.com",    
      projectId: "alinocam-staging",    
      storageBucket: "alinocam-staging.appspot.com",    
      messagingSenderId: "641486880328",    
      appId: "1:641486880328:web:ef3649783f6f32e307227d",    
      measurementId: "G-Z0LHPL71W6"    
    },
    dynamicLinks: {
      shareDataUrl: 'https://staging.alinocam.com',
      shareShortUrl: 'https://staging.alinocam.com/link',
    },
  }
};
