import {
  requestGet,
  requestPost,
  requestDelete,
  requestPut,
} from './api-requests';

import {config} from '@/server.config.js';
import {project} from './api.project';

const serverAddr = config[process.env.VUE_APP_ENV].api;

import store from '@/store/index';

export const admin = {
  _towerFormatter(name) {
    try {
      if (
        name != null &&
        name.length > 0 &&
        name.toUpperCase().startsWith('TURM')
      ) {
        var towerId = name
          .toUpperCase()
          .split('-')[0]
          .replace(/\s/g, '')
          .replace('TURM', '');

        if (!isNaN(towerId))
          return 'TURM' + `00000${parseInt(towerId)}`.slice(-4);
        else name.toUpperCase().split('-')[0].replace(/\s/g, '');
      } else if (
        name != null &&
        name.length > 0 &&
        name.toUpperCase().startsWith('AC')
      ) {
        var alinocamId = name
          .toUpperCase()
          .split('-')[0]
          .replace(/\s/g, '')
          .replace('AC', '');
        if (!isNaN(alinocamId))
          return 'AC' + `00000${parseInt(alinocamId)}`.slice(-4);
        else name.toUpperCase().split('-')[0].replace(/\s/g, '');
      } else return;
    } catch {
      return;
    }
  },

  async updateDeviceName(deviceId, deviceName) {
    return requestPut(serverAddr.gateway, `device/${deviceId}/name`, {
      customDeviceName: deviceName,
    });
  },

  async updateDeviceSettings(deviceId, deviceSettingsObj) {
    return requestPut(
      serverAddr.gateway,
      `device/${deviceId}/settings`,
      deviceSettingsObj,
    );
  },

  async _projectSchedule(projectId) {
    var resp = await project.schedules(projectId, false);

    if (resp && resp.success && resp.data != null && resp.data.total > 0) {
      var matchedSchedule;
      var activeSchedules = resp.data.schedules.sort((a, b) => b.id - a.id);
      matchedSchedule = activeSchedules[0];

      if (activeSchedules.length > 1) {
        activeSchedules.shift();
        await Promise.all(
          activeSchedules.map((schedule) => {
            if (schedule.id != matchedSchedule.id)
              this.archiveSchedule(projectId, schedule.id);
          }),
        );
      }

      return matchedSchedule;
    } else {
      return [];
    }
  },

  async updateProjectCaptureInterval(projectId, interval) {
    return requestPut(
      serverAddr.gateway,
      `device/_/project/${projectId}/captureTime`,
      {captureTime: interval},
    );
  },

  async _handleGetCaptureInterval(responseProject) {
    let interval = 0;
    let captureIntervalPromiseArr = responseProject.data.projects.map(
      async (project) => {
        if (project.id) {
          const url = `device/_/project/${project.id}/captureTime`;
          const res = await requestGet(serverAddr.gateway, url);
          if (!res.data) {
            interval = 0;
          } else {
            interval = res.data.captureTime;
          }
        }

        return new Promise((resolve) => {
          const captureIntervalObj = {
            id: project.id,
            interval,
          };
          resolve(captureIntervalObj);
        });
      },
    );

    return Promise.all(captureIntervalPromiseArr);
  },

  async projects(commit = true) {
    // if (loading) store.commit('loadingAdmin', true)

    var resp = await requestGet(
      serverAddr.gateway,
      `project?page=1&page_size=1000`,
    );

    if (resp != null && resp.success == true) {
      if (resp.data.total == 0) {
        resp.data.projects = [];
      }

      // get capture interval for each project
      const resCaptureInterval = await this._handleGetCaptureInterval(resp);

      var schedulePromiseArr = resp.data.projects.map((project) =>
        this._projectSchedule(project.id),
      );

      var res = await Promise.all(schedulePromiseArr);

      var projectsHandle = resp.data.projects.map((project) => {
        const captureTimeObj = resCaptureInterval[
            resCaptureInterval.findIndex((item) => item.id === project.id)
          ] || null
        return {
          ...project,
          schedule:
            res[
              res.findIndex(
                (schedule) => schedule && schedule.projectId == project.id,
              )
            ],
            
          captureInterval:
            captureTimeObj == null || captureTimeObj['interval'] == null ? 0 : captureTimeObj['interval'],
        };
      });

      if (commit) return store.commit('updateAdminProjects', projectsHandle);
      else return projectsHandle;
    } else {
      if (commit) store.commit('errorAdmin');
      else return resp.data;
    }
  },

  async initialize() {
    store.commit('loadingAdmin', true);
    store.commit('errorAdmin', false);

    try {
      await this.projects();
      await this.devices();

      if (
        store.getters.admin.error == false &&
        (store.getters.admin.projects == null ||
          store.getters.admin.devices == null)
      ) {
        store.commit('errorAdmin', true);
      }
    } catch {
      store.commit('errorAdmin', true);
    }

    store.commit('loadingAdmin', false);
  },

  async createProject(projectConfig) {
    return requestPost(serverAddr.gateway, `project`, projectConfig);
  },

  async updateProjectSettings(projectId, settings) {
    return requestPut(serverAddr.gateway, `project/${projectId}`, settings);
  },

  async updateProjectFeatures(projectId, features) {
    return requestPut(
      serverAddr.gateway,
      `project/${projectId}/permissions`,
      features,
    );
  },

  async credential(credentialId) {
    return requestGet(serverAddr.gateway, `device-secret/${credentialId}`);
  },

  async addDeviceProject(deviceId, projectId) {
    return requestPut(
      serverAddr.gateway,
      `device/${deviceId}/project/${projectId}`,
    );
  },
  updateDeviceCredential(deviceId, credentialId) {
    return requestPut(
      serverAddr.gateway,
      `device-secret/${credentialId}/device/${deviceId}`,
    );
  },

  updateCredential(name, password) {
    if (name == null || password == null) return;

    return requestPost(serverAddr.gateway, `device-secret`, {
      secretName: name,
      secretValue: password,
    });
  },

  async credentials() {
    var resp = await requestGet(serverAddr.gateway, `device-secret`);
    if (resp.success) {
      if (resp.data.total == 0 || resp.data.length == 0)
        return {success: true, data: []};
      // try {
      var filteredCredentials = [];
      resp.data.forEach((credential) => {
        if (
          filteredCredentials.filter(
            (cred) => cred.secretName == credential.secretName,
          ).length > 0
        )
          return;
        var credMatches = resp.data.filter(
          (cred) => cred.secretName == credential.secretName,
        );
        var latestVersion = credMatches.reduce((a, b) =>
          parseInt(a.version) > parseInt(b.version) ? a : b,
        );
        filteredCredentials.push(latestVersion);
      });

      return {success: true, data: filteredCredentials};
    }

    return {success: false, data: []};
  },

  async devices(commit = true) {
    var resp = await requestGet(
      serverAddr.gateway,
      `device?page=1&page_size=10000`,
    );

    if (resp != null && resp.success && resp.data != null) {
      if (resp.data.total == 0) {
        resp.data.devices = [];
      }

      var data = resp.data.devices.map((device) => {
        var result = {
          ...device,
          tower: this._towerFormatter(device.customDeviceName),
          name: device.customDeviceName,
          lastStatus: device.lastPing,
          secretUsage: resp.data.devices.filter(
            (handle) => handle.secretId == device.secretId,
          ).length,
        };

        if (result.projectId == null) {
          result.status = '4';
        }

        if (result.tower != null) {
          // var i = result.customDeviceName.indexOf('-');
          // result.customDeviceName = result.customDeviceName.slice(i + 1)
          result.name = result.name.split('-')[1];
        }
        return result;
      });

      if (commit) return store.commit('updateAdminDevices', data);
      else return data;
    } else {
      // console.log("ERROR")
      if (commit) store.commit('errorAdmin');
      else return resp;
    }
  },

  async user() {
    return requestGet(serverAddr.gateway, 'user');
  },

  async unlinkProjectDevice(deviceId) {
    return requestDelete(serverAddr.gateway, `device/${deviceId}/project`);
  },

  async createNewDevice(cameraConfig) {
    return requestPost(serverAddr.gateway, `device`, cameraConfig);
  },
  async deleteDevice(deviceId) {
    return requestDelete(serverAddr.gateway, `device/${deviceId}`);
  },

  async archiveSchedule(projectId, scheduleId) {
    // console.log("DELETE")
    return requestDelete(
      serverAddr.gateway,
      `device-schedule/${scheduleId}/project/${projectId}`,
    );
  },
};
