import {
  requestPost,
  requestPut,
  requestDelete,
  requestGet,
} from './api-requests';
// import { requestGet, requestPut, requestDelete, requestPost } from './api-requests'

import {config} from '@/server.config.js';

const serverAddr = config[process.env.VUE_APP_ENV].api;

export const gatereport = {
  async gatelog(
    projectId,
    filterObj = {
      dateRange: null,
      gate: null,
      minDuration: null,
      search: null,
      sortBy: 'TIMESTAMP',
      sortDescending: false,
      violations: false,
      whitelistId: null,
    },
    onSiteOnly = false,
    includeImages = true,
  ) {
    // console.log("FILTER OBJ: ", filterObj)
    var queryParams = '';

    if (
      filterObj.dateRange != null &&
      filterObj.dateRange.end != null &&
      filterObj.dateRange.start != null
    ) {
      queryParams += `&startDate=${new Date(
        new Date(filterObj.dateRange.start).setHours(0, 0, 0, 0),
      )}&endDate=${new Date(
        new Date(filterObj.dateRange.end).setHours(23, 59, 59, 999),
      )}`;
    }

    if (filterObj.search != null && filterObj.search.length > 0) {
      queryParams += `&licensePlate=${filterObj.search.toUpperCase()}`;
    }

    if (filterObj.gate != null) {
      queryParams += `&direction=${filterObj.gate}`;
    }

    if (filterObj.sortDescending != null) {
      queryParams += `&sortAsc=${filterObj.sortDescending}`;
    }

    if (filterObj.whitelistId != null) {
      queryParams += `&whitelistId=${filterObj.whitelistId}`;
    }

    if (filterObj.sortBy != null) {
      queryParams += `&sortBy=${filterObj.sortBy}`;
    }

    if (onSiteOnly == true) {
      queryParams += `&duration=${-1}`;
    }

    if (includeImages == false) {
      queryParams += `&exludeImages=true`;
    }

    return requestGet(
      serverAddr.gateway,
      `gate-report/${projectId}/gateLogs?page=${filterObj.page}&pageSize=${filterObj.items}${queryParams}`,
    );
  },

  async createWhitelist(projectId, whitelistData) {
    return requestPost(
      serverAddr.gateway,
      `whitelist/${projectId}`,
      whitelistData,
    );
    // // console.log("REQ - create project: ", projectId, whitelistData);
    // return {success: true, data: {id: 6}}
  },

  // @MOD
  async addVehicleWhitelist(
    projectId,
    whitelistId,
    licensePlateObj,
    updateVehicleLogs = false,
  ) {
    return requestPut(
      serverAddr.gateway,
      `whitelist/${projectId}/${whitelistId}/items`,
      {items: licensePlateObj, sync: updateVehicleLogs},
    );
    // return {success: true}
  },

  // @MOD
  async deletePlateWhitelist(projectId, whitelistId, licensePlate) {
    return requestDelete(
      serverAddr.gateway,
      `whitelist/${projectId}/${whitelistId}/items/${licensePlate}`,
    );
  },

  whitelistByPlate(projectId, licensePlate) {
    return requestGet(
      serverAddr.gateway,
      `whitelist/${projectId}/_/licensePlate/${licensePlate}`,
    );
  },

  whitelist(projectId, whitelistId) {
    return requestGet(
      serverAddr.gateway,
      `whitelist/${projectId}/${whitelistId}`,
    );
  },

  async updateWhitelist(projectId, whitelistId, whitelistObj) {
    return requestPut(
      serverAddr.gateway,
      `whitelist/${projectId}/${whitelistId}`,
      whitelistObj,
    );
  },

  async deleteWhitelist(projectId, whitelistId) {
    return requestDelete(
      serverAddr.gateway,
      `whitelist/${projectId}/${whitelistId}`,
    );
  },

  // async changeVehicleLogStatus(id, status) {
  //     // console.log(id, status);

  //     return {success: true, data: null};
  // },

  // addLicensePlate(whitelistId, licensePlate) {
  //     // console.log("ADD LIC", whitelistId, licensePlate);
  //     return {success: true}
  // },

  async projectGates(projectId) {
    return requestGet(
      serverAddr.gateway,
      `device/_/project/${projectId}/deviceNames`,
    );
  },

  async vehicleLogs(
    projectId,
    filterObj = {
      dateRange: null,
      search: null,
      sortBy: 'TIMESTAMP',
      whitelistId: null,
      page: 1,
      items: 20,
    },
    onlyViolations,
    includeImages = true,
  ) {
    var queryParams = '';

    // console.log("L", filterObj)
    if (filterObj.sortBy != null) {
      queryParams += `&sortBy=${filterObj.sortBy}`;
    }

    if (
      filterObj.dateRange != null &&
      filterObj.dateRange.end != null &&
      filterObj.dateRange.start != null
    ) {
      queryParams += `&startDate=${new Date(
        new Date(filterObj.dateRange.start).setHours(0, 0, 0, 0),
      )}&endDate=${new Date(
        new Date(filterObj.dateRange.end).setHours(23, 59, 59, 999),
      )}`;
    }

    if (filterObj.search != null && filterObj.search.length > 0) {
      queryParams += `&licensePlate=${filterObj.search.toUpperCase()}`;
    }

    if (filterObj.sortDescending != null) {
      queryParams += `&sortAsc=${filterObj.sortDescending}`;
    }

    if (filterObj.whitelistId != null) {
      queryParams += `&whitelistId=${filterObj.whitelistId}`;
    }

    if (onlyViolations == true) {
      queryParams += `&onlyViolations=true`;
    }

    if (includeImages == false) {
      queryParams += `&exludeImages=true`;
    }

    return requestGet(
      serverAddr.gateway,
      `gate-report/${projectId}/vehicleLogs?page=${filterObj.page}&pageSize=${filterObj.items}${queryParams}`,
    );
  },

  async vehicleLog(projectId, id) {
    return requestGet(
      serverAddr.gateway,
      `gate-report/${projectId}/vehicleLog/${id}`,
    );
  },

  async whitelists(projectId) {
    return requestGet(serverAddr.gateway, `whitelist/${projectId}`);

    // return requestGet(serverAddr.gateway, `project/${projectId}`);
  },
};
