/* eslint-disable no-use-before-define */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import store from '../../store/index';
import {i18n} from '@/plugins/i18n';
import {user} from '../server/api.user';
import {getAuth, setPersistence, browserLocalPersistence} from 'firebase/auth';

export default {
  install(Vue) {
    const auth = firebase.auth();
    const authService = getAuth();

    function getUser() {
      return new Promise((resolve) => {
        let unsub = authService.onAuthStateChanged(function (user) {
          unsub();
          resolve(user);
        });
      });
    }

    Vue.prototype.$auth = {
      auth,

      canWrite() {
        if (
          store.getters.user.internal == true ||
          this.isProjectOwner() == true
        )
          return true;
        if (
          store.getters.user == null ||
          store.getters.user.id == null ||
          store.getters.project.general == null ||
          store.getters.project.general == null ||
          store.getters.project.general.members == null
        )
          return false;
        var user = store.getters.project.general.members.reduce((a, b) =>
          a.userId == store.getters.user.id ? a : b,
        );
        console.log(user);
        if (user == null || user == false || user.role == null) return false;
        else return user.role == 1;
      },

      isProjectOwner() {
        if (store.getters.user.internal == true) return true;

        if (
          store.getters.user == null ||
          store.getters.user.id == null ||
          store.getters.project.general == null ||
          store.getters.project.general == null ||
          store.getters.project.general.members == null
        )
          return false;
        var user = store.getters.project.general.members.reduce((a, b) =>
          a.userId == store.getters.user.id ? a : b,
        );

        if (user == null || user == false || user.role == null) return false;
        else return user.role == 2;
      },

      async logout() {
        store.commit('removeCurrentUser');
        return await auth.signOut();
      },

      async isLoggedIn() {
        var userHandle = await getUser();

        if (userHandle == null || userHandle.uid == null) return false;
        else {
          var currentUser = store.getters.currentUser;

          var tokenHandle =
            currentUser != null && currentUser.api != null
              ? currentUser.api
              : null;

          if (
            tokenHandle == null ||
            +new Date(tokenHandle.expirationTime) - +new Date() <= 900000
          ) {
            tokenHandle = await userHandle.getIdTokenResult(true);
          }

          store.commit('updateCurrentUserToken', {
            id: userHandle.uid,
            api: tokenHandle,
            meta: currentUser.meta || null,
          });

          return true;
        }
      },
      /**
       * landingPage()
       * Functions determines users landing page based on (internal) rights and returns vue-router redirect object
       *
       * @returns {Object} vue-router redirect Object with lang as parameter
       */
      landingPage() {
        const user = store.getters.user;

        if (user == null)
          return {name: 'login', params: {lang: i18n.locale || 'de'}};
        else if (user.internal == true)
          return {
            name: 'project_overview',
            params: {lang: i18n.locale || 'de'},
          };
        else
          return {
            name: 'project_overview',
            params: {lang: i18n.locale || 'de'},
          };
      },

      async resetPassword(newPassword, code) {
        // console.log(code, key)
        try {
          await auth.confirmPasswordReset(code, newPassword);
          return true;
        } catch {
          return;
        }
      },

      async verifyCode(oobCode) {
        try {
          return await auth.verifyPasswordResetCode(oobCode);
        } catch {
          return;
        }
      },

      async verifyRegisterCode(oobCode) {
        try {
          return await auth.checkActionCode(oobCode);
        } catch {
          return;
        }
      },

      async verifyMail(oobCode) {
        try {
          return await auth.applyActionCode(oobCode);
        } catch {
          return false;
        }
      },

      async updatePassword(mail, currPassword, newPassword) {
        try {
          const authResp = await (
            await auth.signInWithEmailAndPassword(mail, currPassword)
          ).user.updatePassword(newPassword);
          return {success: true, message: authResp};
        } catch (err) {
          let message = err.code;
          return {success: false, message: message};
        }
      },

      async updateUsername(mail, currPassword, name) {
        try {
          const authResp = await (
            await auth.signInWithEmailAndPassword(mail, currPassword)
          ).user.updateProfile({
            displayName: name,
          });
          return {success: true, message: authResp};
        } catch (err) {
          let message = err.code;
          return {success: false, message: message};
        }
      },

      async register(name, mail, password) {
        try {
          var registerResult = await firebase
            .auth()
            .createUserWithEmailAndPassword(mail, password);
        } catch (error) {
          return {success: false, error: error.code};
        }

        try {
          var nameResult = await registerResult.user.updateProfile({
            displayName: name,
          });
          // // console.ll(nameResult)
          return {success: true, data: nameResult};
        } catch (error) {
          return {success: false, error: error.code};
        }
      },

      /**
       * login
       *
       * @param {String} username username (email)
       * @param {String} password user's password
       * @returns {Object} if successfull returing user, else error code
       */
      async login(username, password) {
        try {
          let userCredential = await auth.signInWithEmailAndPassword(
            username,
            password,
          );

          // Failure on login or invalid data
          if (
            !userCredential ||
            !userCredential.user ||
            userCredential.user.uid == null
          )
            throw new Error();

          var userHandle = await getUser();
          if (userHandle == null || userHandle.uid == null) throw new Error();

          // Setting accounts persistence to 'local'
          setPersistence(authService, browserLocalPersistence);

          var tokenHandle = await userHandle.getIdTokenResult();

          store.commit('updateCurrentUserToken', {
            id: userHandle.uid,
            api: tokenHandle,
          });

          // get user information (meta) and user projects simultanously
          var userResp = await Promise.all([
            user.user(userHandle.uid),
            user.projects(),
          ]);

          // check if request was successfull & required data is present --> update vuex store data
          if (
            !userResp.some((resp) => resp.success == false) &&
            userResp[0].data != null &&
            userResp[1].data != null
          ) {
            store.commit('updateCurrentUserMeta', {
              ...userResp[0].data,
              internal: userResp[0].data.type == 1,
              projects: userResp[1].data.projects || [],
            });
          } else throw new Error();
          return {
            success: true,
            user: userHandle,
            isNewUser: !userCredential.user.emailVerified,
          };
        } catch (err) {
          return {success: false, message: err.code || 'unknown-error'};
        }
      },
    };
  },
};
