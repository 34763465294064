// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const axios = require('axios');
axios.defaults.timeout = 60000;
import router from '../../router/index';
import store from '../../store/index';
// import Vue from 'vue';

const requestHeader = async function (auth) {
  if (
    auth &&
    (!store.getters.currentUser ||
      store.getters.currentUser.api == null ||
      store.getters.currentUser.api.token == null)
  )
    return router.push({
      name: 'login',
      params: {lang: router.currentRoute.params.lang},
      query: {type: 'reload', route: router.currentRoute.path, id: 1},
    });

  if (auth) {
    return {
      Authorization: `Bearer ${store.getters.currentUser.api.token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, PATCH, OPTIONS',
      'Access-Control-Allow-Headers': '*',
    };
  } else {
    return {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, PATCH, OPTIONS',
      'Access-Control-Allow-Headers': '*',
    };
  }
};

export const badFormatResponse = function () {
  return {success: false, status: undefined, error: 'body-bad-format'};
};

const handleResponse = async function (requestPromise) {
  try {
    var resp = await requestPromise;

    if (resp.status == 200 || resp.status == 201)
      return {success: true, data: resp.data};
    else if (resp.status == 401 && router.currentRoute.name != 'login')
      return router.push({
        name: 'login',
        query: {type: 'reload', route: router.currentRoute.path, id: 2},
      });
    else return {success: false, status: resp.status, error: resp.data};
  } catch (error) {
    if (error.message.includes(401) && router.currentRoute.name != 'login')
      return router.push({
        name: 'login',
        query: {type: 'reload', route: router.currentRoute.path, id: 3},
      });
    else return {success: false, status: 500, error: error};
  }
};

export const requestGet = async function (server, url, auth = true) {
  // if (auth && router.currentRoute.name == 'login') return;
  return await handleResponse(
    axios.get(`${server}/${url}`, {headers: await requestHeader(auth)}),
  );
};

export const requestPost = async function (server, url, payload, auth = true) {
  if (auth && router.currentRoute.name == 'login') return;
  return await handleResponse(
    axios.post(`${server}/${url}`, payload, {
      headers: await requestHeader(auth),
    }),
  );
};

export const requestPut = async function (server, url, payload, auth = true) {
  if (auth && router.currentRoute.name == 'login') return;
  return await handleResponse(
    axios.put(`${server}/${url}`, payload, {
      headers: await requestHeader(auth),
    }),
  );
};

export const requestDelete = async function (
  server,
  url,
  payload = null,
  auth = true,
) {
  if (auth && router.currentRoute.name == 'login') return;
  return await handleResponse(
    axios.delete(`${server}/${url}`, {
      headers: await requestHeader(auth),
      data: {source: payload},
    }),
  );
};
