import {FirebaseDynamicLinks} from 'firebase-dynamic-links';
import {i18n} from '@/plugins/i18n';
import {config} from '@/server.config';

export default {
  install(Vue) {
    const firebaseDynamicLinks = new FirebaseDynamicLinks(
      config[process.env.VUE_APP_ENV].firebase.apiKey,
    );

    Vue.prototype.$share = {
      embedLink(link, lang = 'de', video = false) {
        return `${
          config[process.env.VUE_APP_ENV].dynamicLinks.shareDataUrl
        }/share/embed?${link}&lang=${lang}&video=${video}`;
      },
      async link(link, title, descr, type, thumbnail) {
        try {
          const shortLink = await firebaseDynamicLinks.createLink({
            dynamicLinkInfo: {
              domainUriPrefix:
                config[process.env.VUE_APP_ENV].dynamicLinks.shareShortUrl,
              link: `${
                config[process.env.VUE_APP_ENV].dynamicLinks.shareDataUrl
              }/share/${type}?${link}&lang=${i18n.locale || 'de'}`,
              socialMetaTagInfo: {
                socialTitle: title,
                socialDescription: descr,
                socialImageLink: thumbnail || null,
              },
            },
          });

          return shortLink;
        } catch {
          return;
        }
      },
    };
  },
};
