import {
  requestPost,
  requestGet,
  requestDelete,
  requestPut,
} from './api-requests';

import {config} from '@/server.config.js';

const serverAddr = config[process.env.VUE_APP_ENV].api;

export const user = {
  delete(id) {
    return requestDelete(serverAddr.gateway, `user/${id}`);
  },
  user(id) {
    return requestGet(serverAddr.gateway, `user/${id}`);
  },
  projects() {
    return requestGet(serverAddr.gateway, `project?page=1&page_size=10000`);
  },
  restore(mailAddr) {
    return requestPost(
      serverAddr.gateway,
      `unauthenticated/forgot-password`,
      {email: mailAddr},
      false,
    );
  },

  resendInvite(userId) {
    return requestPut(serverAddr.gateway, `user/${userId}/resend-invitation`);
  },

  invite(userObj) {
    return requestPost(serverAddr.gateway, `user`, userObj);
  },

  async userByMail(userMail) {
    try {
      const resp = await requestGet(
        serverAddr.gateway,
        `user/_/email/${userMail}`,
      );

      if (resp.success == true && resp.data.email == userMail) {
        return resp.data;
      }
    } catch {
      return;
    }
  },

  async isUser(userMail) {
    try {
      const resp = await requestGet(
        serverAddr.gateway,
        `user/_/email/${userMail}`,
      );
      if (resp.success == true && resp.data.email == userMail) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  },
};
