import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

// CSS imports
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import './scss/toast.scss';
import 'vue-toastification/dist/index.css';
import './registerServiceWorker';
import './assets/fonts/Lato/stylesheet.css';

import VCalendar from 'v-calendar';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueFullscreen from 'vue-fullscreen';
import VueDraggableResizable from 'vue-draggable-resizable';
import Toast, {POSITION} from 'vue-toastification';
import VueLocalStorage from 'vue-local-storage';
import VueOffline from 'vue-offline';

Vue.use(VueOffline);

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.use(VueFullscreen);
Vue.component('vue-draggable-resizable', VueDraggableResizable);


import VueKonva from 'vue-konva';
Vue.use(VueKonva);

// Import the CSS or use your own!

import plugin_api from './plugins/server/api';

import plugin_auth from './plugins/firebase/auth';
import plugin_share from './plugins/firebase/share';
import plugin_helper from './plugins/helper';
import plugin_pdf from './plugins/helper.pdf';
import {setupProject} from './plugins/firebase/setupProject';

import {config} from './server.config.js';
import {i18n} from '@/plugins/i18n';


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCD22BsdEw3MQSjR2UBpipW4Kb7B7sfrcQ',
    libraries: 'places',
  },
});

// ----------------------------------------------------------------------
// ------------------------------ FIREBASE ------------------------------
// ----------------------------------------------------------------------
// initialize firebase config globally
console.log(process.env.VUE_APP_ENV);
setupProject(config[process.env.VUE_APP_ENV].firebase);

Vue.use(plugin_helper);
Vue.use(plugin_pdf);
Vue.use(plugin_auth);
Vue.use(plugin_api);
Vue.use(plugin_share);
Vue.use(VueLocalStorage);

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 4,
  icon: false,
  position: POSITION.BOTTOM_RIGHT,
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  transitionDuration: {
    enter: 50,
    leave: 50,
  },
});


Vue.prototype.$product = 'AlinoCam'

// ----------------------------------------------------------------------
// ------------------------------ FILTER --------------------------------
// ----------------------------------------------------------------------
import filter from './common/filters/'

Vue.filter('monthFilter', filter.monthFilter)
Vue.filter('formattedDataFilter', filter.formattedDataFilter)

// ----------------------------------------------------------------------
// ------------------------ VUE-COMPOSITION-API -------------------------
// ----------------------------------------------------------------------
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
