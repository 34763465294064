// import { requestGet } from './api-requests'
import {project} from './api.project';
import {user} from './api.user';
import {admin} from './api.admin';
import {gatereport} from './api.gatereport';

export default {
  install(Vue) {
    Vue.prototype.$api = {
      admin,
      user,
      gatereport,
      project,
    };
  },
};
