import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const de_router = require('@/languages/de/router.json');
const en_router = require('@/languages/en/router.json');

const de_auth = require('@/languages/de/auth.json');
const en_auth = require('@/languages/en/auth.json');

const de_pdf_gatereport = require('@/languages/de/pdf/gatereport.pdf.json');
const de_pdf_vehiclelog = require('@/languages/de/pdf/vehiclelog.pdf.json');
const de_pdf_whitelist = require('@/languages/de/pdf/whitelist.pdf.json');

const en_pdf_gatereport = require('@/languages/en/pdf/gatereport.pdf.json');
const en_pdf_vehiclelog = require('@/languages/en/pdf/vehiclelog.pdf.json');
const en_pdf_whitelist = require('@/languages/en/pdf/whitelist.pdf.json');

const de_project_management_gatereport_log = require('@/languages/de/tools/project/gate-report-log.json');
const en_project_management_gatereport_log = require('@/languages/en/tools/project/gate-report-log.json');

const de_project_management_gatereport_violations = require('@/languages/de/tools/project/gate-report-violations.json');

const de_project_management_gatereport_whitelists = require('@/languages/de/tools/project/gate-report-whitelist.json');
// CAMERA MANAGEMENT
const de_camera_management_credentials = require('@/languages/de/tools/camera-management/credentials.json');
const en_camera_management_credentials = require('@/languages/en/tools/camera-management/credentials.json');

const de_camera_management_devices = require('@/languages/de/tools/camera-management/devices.json');
const en_camera_management_devices = require('@/languages/en/tools/camera-management/devices.json');

const de_project_management_dashboard = require('@/languages/de/tools/project/dashboard.json');
const en_project_management_dashboard = require('@/languages/en/tools/project/dashboard.json');

const de_project_management_timelapse = require('@/languages/de/tools/project/timelapse.json');
const en_project_management_timelapse = require('@/languages/en/tools/project/timelapse.json');

const de_project_management_page = require('@/languages/de/tools/project/page.json');
const en_project_management_page = require('@/languages/en/tools/project/page.json');

const de_project_management_arming = require('@/languages/de/tools/project/arming.json');
const en_project_management_arming = require('@/languages/en/tools/project/arming.json');

const de_project_management_settings = require('@/languages/de/tools/project/settings.json');
const en_project_management_settings = require('@/languages/en/tools/project/settings.json');

const de_project_management_share = require('@/languages/de/tools/project/share.json');
const en_project_management_share = require('@/languages/en/tools/project/share.json');

const de_project_management_recordings = require('@/languages/de/tools/project/recordings.json');
const en_project_management_recordings = require('@/languages/en/tools/project/recordings.json');

const de_project_overview = require('@/languages/de/tools/project/overview.json');
const en_project_overview = require('@/languages/en/tools/project/overview.json');

const de_admin_projects = require('@/languages/de/tools/admin/projects.json');

const de_admin_devices = require('@/languages/de/tools/admin/devices.json');
const en_admin_devices = require('@/languages/en/tools/admin/devices.json');

const de_nirvana = require('@/languages/de/nirvana.json');
const en_nirvana = require('@/languages/en/nirvana.json');

const de_helper = require('@/languages/de/helper.json');
const en_helper = require('@/languages/en/helper.json');

export const supportedLanguages = ['de', 'en'];
export const configureLanguage = function (language) {
  if (!language || !supportedLanguages.includes(language)) {
    language = 'de';
  } else {
    i18n.locale = language;
  }

  return language;
};
export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',

  messages: {
    de: {
      pdf: {
        gatereport: de_pdf_gatereport,
        vehiclelog: de_pdf_vehiclelog,
        whitelist: de_pdf_whitelist,
      },
      router: de_router,
      nirvana: de_nirvana,
      auth: de_auth,
      helper: de_helper,
      tools: {
        admin: {
          projects: de_admin_projects,
          devices: de_admin_devices,
        },
        management: {
          credentials: de_camera_management_credentials,
          devices: de_camera_management_devices,
        },

        project: {
          whitelists: de_project_management_gatereport_whitelists,
          violations: de_project_management_gatereport_violations,
          log: de_project_management_gatereport_log,
          overview: de_project_overview,
          timelapse: de_project_management_timelapse,
          recordings: de_project_management_recordings,
          page: de_project_management_page,
          arming: de_project_management_arming,
          settings: de_project_management_settings,
          dashboard: de_project_management_dashboard,
          share: de_project_management_share,
        },
      },
    },
    en: {
      pdf: {
        gatereport: en_pdf_gatereport,
        vehiclelog: en_pdf_vehiclelog,
        whitelist: en_pdf_whitelist,
      },
      router: en_router,
      helper: en_helper,
      nirvana: en_nirvana,
      auth: en_auth,
      tools: {
        admin: {
          devices: en_admin_devices,
        },
        management: {
          credentials: en_camera_management_credentials,
          devices: en_camera_management_devices,
        },
        project: {
          log: en_project_management_gatereport_log,
          recordings: en_project_management_recordings,
          page: en_project_management_page,
          overview: en_project_overview,
          timelapse: en_project_management_timelapse,
          arming: en_project_management_arming,
          dashboard: en_project_management_dashboard,
          settings: en_project_management_settings,
          share: en_project_management_share,
        },
      },
    },
  },
});
