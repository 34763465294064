import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#cc2027',
        secondary: '#424242',
        accent: '#82B1FF',
        info: '#2196F3',
        warning: '#FFC107',
        error: '#FF5252',
        success: '#4CAF50',
        background: '#F5F5F5',
      },
    },
  },
    lang: {
      locales: { de, en },
      current: 'de',
    },
});
