import Vue from 'vue';
import VueRouter from 'vue-router';
import {routes} from './routes.js';
import {configureLanguage} from '@/plugins/i18n';

import {i18n} from '@/plugins/i18n';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // check if user is logged in
  var isLoggedIn = await Vue.prototype.$auth.isLoggedIn();

  if (to.name == 'app')
    return next({
      name: isLoggedIn ? 'project_overview' : 'login',
      params: {lang: i18n.locale || 'de'},
    });

  // configure language (i18n); if language is present in localStorage this is used as default language
  configureLanguage(to.params.lang);

  // forward if route is login or no auth required
  if (to.name == 'login' || to.meta.auth == false) return next();

  // redirect to login page if user is not logged in
  if (!isLoggedIn) {
    return next({
      name: 'login',
      params: {
        lang: to.params.lang || i18n.locale,
        query: {
          type: 'reload',
          route: to.path,
          id: 4,
        },
      },
    });
  } else {
    if (to.name == 'app') return next(Vue.prototype.$auth.landingPage());
    else next();
  }
});

export default router;
