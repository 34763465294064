// import {i18n} from '@/plugins/i18n';
import prettyBytes from 'pretty-bytes';
import {i18n} from '@/plugins/i18n';

const convertMBtoByte = (data) => {
  const result = Number(data) * 1000 * 1024;
  return result;
};

// The type of data get from server is MB so we need to convert it
// to Byte that can be formatted.
export const formattedDataFilter = (mbData) => {
  const byteData = convertMBtoByte(mbData);
  return prettyBytes(byteData, {locale: i18n.locale});
};
