import {i18n} from '@/plugins/i18n';
export const routes = [
  // --------------------------------------------------------------------
  // ---------------------------- REDIRECTS -----------------------------
  // --------------------------------------------------------------------

  {
    path: '/',
    name: 'app',
    component: {
      render(c) {
        return c('router-view');
      },
    },
  },
  {
    path: '*',
    redirect: {name: 'not-found', params: {lang: i18n.locale || 'de'}},
  },

  {
    path: '/share',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {auth: false, ...i18n.t('router.auth.login')},
    redirect: {
      name: 'not-found',
      query: {type: 'link'},
      params: {lang: i18n.locale || 'de'},
    },
    children: [
      {
        path: 'embed',
        name: 'share-inside-frame',
        meta: {auth: false, ...i18n.t('router.auth.login')},
        component: () => import('@/views/share/SharediFrameView.vue'),
      },

      {
        path: 'timelapse',
        name: 'share-timelapse',
        meta: {auth: false, ...i18n.t('router.auth.login')},
        component: () => import('@/views/share/SharedVideoView.vue'),
        beforeEnter: (to, from, next) => {
          // console.log("to", to, from)
          if (
            to == null ||
            to.query == null ||
            to.query.expiresAt == null ||
            new Date() > new Date(to.query.expiresAt) ||
            to.query.link == null
          ) {
            return next({
              name: 'not-found',
              query: {type: 'link'},
              params: {lang: i18n.locale || 'de'},
            });
          } else return next();
        },
      },

      {
        path: 'image',
        name: 'share-image',
        meta: {auth: false},
        component: () => import('@/views/share/SharedImageView.vue'),
        beforeEnter: (to, from, next) => {
          // console.log("to", to, from)
          if (
            to == null ||
            to.query == null ||
            to.query.expiresAt == null ||
            new Date() > new Date(to.query.expiresAt)
          ) {
            return next({
              name: 'not-found',
              query: {type: 'link'},
              params: {lang: i18n.locale || 'de'},
            });
          } else return next();
        },
      },

      {
        path: 'slider',
        name: 'share-slider',
        meta: {auth: false},
        component: () => import('@/views/share/SharedSliderCompareView.vue'),
        beforeEnter: (to, from, next) => {
          // console.log("to", to, from)
          if (
            to == null ||
            to.query == null ||
            to.query.expiresAt == null ||
            new Date() > new Date(to.query.expiresAt)
          ) {
            return next({
              name: 'not-found',
              query: {type: 'link'},
              params: {lang: i18n.locale || 'de'},
            });
          } else return next();
        },
      },

      {
        path: 'area',
        name: 'share-area',
        meta: {auth: false},
        component: () => import('@/views/share/SharedAreaCompareView.vue'),
        beforeEnter: (to, from, next) => {
          // console.log("to", to, from)
          if (
            to == null ||
            to.query == null ||
            to.query.expiresAt == null ||
            new Date() > new Date(to.query.expiresAt)
          ) {
            return next({
              name: 'not-found',
              query: {type: 'link'},
              params: {lang: i18n.locale || 'de'},
            });
          } else return next();
        },
      },
    ],
  },

  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    redirect: {name: 'login', params: {lang: i18n.locale || 'de'}},
    children: [
      // --------------------------------------------------------------------
      // ---------------------------- REDIRECTS -----------------------------
      // --------------------------------------------------------------------

      {
        path: 'login',
        redirect: {name: 'login', params: {lang: i18n.locale || 'de'}},
      },

      {
        path: 'projects',
        redirect: {name: 'project_overview'},
      },

      {
        path: 'terms',
        meta: {auth: false, ...i18n.t('router.auth.login')},
        component: () => import('../views/general/TermsView.vue'),
      },

      {
        path: 'nirvana',
        name: 'not-found',
        meta: {auth: false},
        component: () => import('@/views/general/404View.vue'),
        beforeEnter: (to, from, next) => {
          if (from.name == 'project_overview' || from.name == 'login')
            return false;
          else next();
        },
      },

      // --------------------------------------------------------------------
      // ------------------------------ PAGES -------------------------------
      // --------------------------------------------------------------------

      //   {
      //     path: 'admin',
      //     component: () => import('../views/tools/admin/AdminVueNavigation.vue'),
      //     redirect: {name: 'admin_dashboard'},
      //     children: [
      //       {
      //         path: 'dashboard',
      //         name: 'admin_dashboard',
      //         meta: i18n.t('router.admin.dashboard'),
      //         component: () =>
      //           import('../views/tools/admin/AdminViewDashboard.vue'),
      //       },

      //       {
      //         path: 'devices',
      //         name: 'admin_devices',
      //         meta: i18n.t('router.admin.devices'),
      //         component: () =>
      //           import('../views/tools/admin/AdminViewDevices.vue'),
      //       },

      //       {
      //         path: 'projects',
      //         name: 'admin_projects',
      //         meta: i18n.t('router.admin.projects'),
      //         component: () =>
      //           import('../views/tools/admin/AdminViewProjects.vue'),
      //       },

      //       {
      //         path: 'user',
      //         name: 'admin_user',
      //         meta: i18n.t('router.admin.user'),
      //         component: () => import('../views/tools/admin/AdminViewUser.vue'),
      //       },
      //     ],
      //   },

      // --------------------------------------------------------------------
      // ---------------------------- ALINOPLAN -----------------------------
      // --------------------------------------------------------------------
      {
        path: 'project',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        redirect: {name: 'project_overview'},
        children: [
          {
            path: 'overview',
            name: 'project_overview',
            meta: {auth: true, ...i18n.t('router.overview')},
            component: () =>
              import('../views/tools/project/ProjectViewOverview.vue'),
            beforeEnter: (to, from, next) => {
              next();
            },
          },

          {
            path: ':projectId',
            name: 'project_page',
            meta: {auth: true},
            component: () =>
              import('../views/tools/project/ProjectViewNavigation.vue'),
            redirect: {name: 'project_dashboard'},
            beforeEnter: (to, from, next) => {
              next();
            },

            children: [
              {
                path: 'overview',
                name: 'project_dashboard',
                meta: {
                  hideMobile: false,
                  required: true,
                  auth: true,
                  i18n: 'router.project.overview',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewDashboard.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  next();
                },
              },

              {
                path: 'timelapse',
                name: 'project_timelapse',
                meta: {
                  hideMobile: false,
                  allowRecordings: true,
                  auth: true,
                  i18n: 'router.project.timelapse',
                },

                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewTimelapse.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  next();
                },
              },
              {
                path: 'recordings',
                name: 'project_recordings',
                meta: {
                  hideMobile: true,
                  allowRecordings: true,
                  auth: true,
                  i18n: 'router.project.recordings',
                },

                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewRecordings.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  next();
                },
              },
              {
                path: 'compare',
                name: 'project_images_compare',
                meta: {
                  hideMobile: true,
                  allowRecordings: true,
                  auth: true,
                  i18n: 'router.project.compare.parent',
                },
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                // redirect: {name: 'project_settings_project'},
                redirect: {name: 'project_images_compare_slider'},
                children: [
                  {
                    path: 'slider',
                    name: 'project_images_compare_slider',
                    meta: {
                      allowRecordings: true,
                      auth: true,
                      i18n: 'router.project.compare.slider',
                    },
                    component: () =>
                      import(
                        '../views/tools/project/functions/compare/ProjectViewCompareSlider.vue'
                      ),
                  },

                  {
                    path: 'area',
                    name: 'project_images_compare_area',
                    meta: {
                      allowRecordings: true,
                      auth: true,
                      i18n: 'router.project.compare.area',
                    },
                    component: () =>
                      import(
                        '../views/tools/project/functions/compare/ProjectViewCompareArea.vue'
                      ),
                  },
                ],
              },

              {
                path: 'schedule',
                name: 'project_schedule',
                meta: {
                  hideMobile: false,
                  allowRecordings: true,
                  allowArming: true,
                  auth: true,
                  i18n: 'router.project.schedule',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewSchedule.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  next();
                },
              },

              {
                path: 'alarm-areas',
                name: 'project_alarm_areas',
                meta: {
                  hideMobile: false,
                  allowArming: true,
                  auth: true,
                  i18n: 'router.project.alarm-areas',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewAlarmAreas.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  next();
                },
              },

              {
                path: 'gate-report',
                name: 'project_gate_report',
                meta: {
                  hideMobile: false,
                  dividerTop: true,
                  allowParkControl: true,
                  auth: true,
                  i18n: 'router.project.gatereport',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewGateReport.vue'
                  ),
              },
              {
                path: 'violations',
                name: 'project_violations',
                meta: {
                  hideMobile: false,
                  allowParkControl: true,
                  auth: true,
                  i18n: 'router.project.violations',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewViolations.vue'
                  ),
              },

              {
                path: 'whitelist',
                name: 'project_whitelist',
                meta: {
                  hideMobile: false,
                  allowParkControl: true,
                  auth: true,
                  i18n: 'router.project.whitelist',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/ProjectViewWhitelist.vue'
                  ),
              },

              {
                path: 'settings',
                name: 'project_settings',
                meta: {
                  hideMobile: true,
                  dividerTop: true,
                  required: true,
                  auth: true,
                  i18n: 'router.project.settings.parent',
                },
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                // redirect: {name: 'project_settings_project'},
                redirect: {name: 'project_settings_general'},
                children: [
                  {
                    path: 'general',
                    name: 'project_settings_general',
                    meta: {
                      auth: true,
                      required: true,
                      i18n: 'router.project.settings.general',
                    },
                    component: () =>
                      import(
                        '../views/tools/project/functions/settings/ProjectViewSettings.vue'
                      ),
                  },

                  {
                    path: 'user',
                    name: 'project_settings_user',
                    meta: {
                      auth: true,
                      required: true,
                      i18n: 'router.project.settings.user',
                    },
                    component: () =>
                      import(
                        '../views/tools/project/functions/settings/ProjectViewUser.vue'
                      ),
                  },
                  {
                    path: 'embed',
                    name: 'project_settings_embed',
                    meta: {
                      auth: true,
                      allowRecordings: true,
                      i18n: 'router.project.settings.embed',
                    },
                    component: () =>
                      import(
                        '../views/tools/project/functions/settings/ProjectViewEmbed.vue'
                      ),
                  },

                  // ================================================================
                  // Project setting data usage here
                  // ================================================================
                ],
              },
              {
                path: 'data-usage',
                name: 'project_settings_usage',
                meta: {
                  auth: true,
                  allowRecordings: true,
                  i18n: 'router.project.usage',
                },
                component: () =>
                  import(
                    '../views/tools/project/functions/settings/ProjectViewUsage.vue'
                  ),
              },
            ],
          },
        ],
      },

      // // --------------------------------------------------------------------
      // // --------------------------- ALINODEVICES----------------------------
      // // --------------------------------------------------------------------
      // {
      //     path: 'camera',
      //     component: { render(c) { return c('router-view') } },
      //     redirect: { name: 'camera_overview' },
      //     children: [
      //         {
      //             path: 'overview',
      //             name: 'camera_overview',
      //             meta: {auth: true, ...i18n.t('router.overview')},
      //             component: () => import('../views/tools/camera-mangement/CameraOverview.vue'),
      //             beforeEnter: (to, from, next) => {
      //                 // console.l(to)
      //                 // console.l("CAMERA OVERVIEW")
      //                 next()
      //             }
      //         },
      //  ]
      // },

      // --------------------------------------------------------------------
      // ---------------------------- ALINOPLAN -----------------------------
      // --------------------------------------------------------------------
      // {
      //     path: 'plan',
      //     component: { render(c) { return c('router-view') } },
      //     redirect: { name: 'plan_overview' },
      //     children: [
      //         {
      //             path: 'overview',
      //             name: 'plan_overview',
      //             meta: {auth: true, ...i18n.t('router.overview')},
      //             component: () => import('../views/tools/project-planning/PlansOverview.vue'),
      //             beforeEnter: (to, from, next) => {
      //                 // console.l(to)
      //                 // console.l("CAMERA OVERVIEW")
      //                 next()
      //             }
      //         },
      //  ]
      // },

      // --------------------------------------------------------------------
      // -------------------------- ALINOMARKETING --------------------------
      // --------------------------------------------------------------------
      // {
      //     path: 'marketing',
      //     component: { render(c) { return c('router-view') } },
      //     redirect: { name: 'marketing_overview' },
      //     children: [
      //         {
      //             path: 'overview',
      //             name: 'marketing_overview',
      //             meta: {auth: true, ...i18n.t('router.overview')},
      //             component: () => import('../views/tools/marketing/MarketingOverview.vue'),
      //             beforeEnter: (to, from, next) => {
      //                 // console.l(to)
      //                 // console.l("CAMERA OVERVIEW")
      //                 next()
      //             }
      //         },
      //  ]
      // },

      // Overview Page
      {
        path: 'auth',
        name: 'auth',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        redirect: {name: 'login'},
        children: [
          {
            path: 'login',
            name: 'login',
            meta: {auth: false, ...i18n.t('router.auth.login')},
            component: () => import('../views/auth/LoginView.vue'),
          },

          {
            path: 'register',
            name: 'register',
            meta: {auth: false, ...i18n.t('router.auth.register')},
            component: () => import('../views/auth/RegisterView.vue'),
          },

          {
            path: 'restore',
            name: 'restore',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            redirect: {name: 'restore-mail'},
            children: [
              {
                path: 'request',
                name: 'restore-mail',
                meta: {auth: false, ...i18n.t('router.auth.restore')},
                component: () => import('../views/auth/RestoreView.vue'),
              },
              {
                path: 'reset',
                name: 'restore_reset',
                meta: {auth: false, ...i18n.t('reset')},
                beforeEnter: (to, from, next) => {
                  if (to.query.oobCode == null)
                    return next({
                      name: 'login',
                      params: {lang: i18n.locale || 'de'},
                    });
                  else return next();
                },
                component: () => import('../views/auth/ResetView.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
];
