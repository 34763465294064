import {
  requestGet,
  requestPut,
  requestDelete,
  requestPost,
} from './api-requests';

import {config} from '@/server.config.js';
const axios = require('axios');

const serverAddr = config[process.env.VUE_APP_ENV].api;

import store from '@/store/index';

export const project = {
  /**
   * destroy
   *
   * remove all project related data from vuex store
   *
   */
  destroy() {
    return store.commit('destroyProject');
  },

  /**
   * projects
   *
   * get all project user has access to; internal user receive all projects
   *
   * @returns
   */
  async projects() {
    return requestGet(serverAddr.gateway, `project?page=1&page_size=1000`);
  },

  /**
   * delete
   *
   * archive project by project Id
   *
   * @param {String} projectId project unique id
   * @returns
   */
  async delete(projectId) {
    return requestDelete(serverAddr.gateway, `project/${projectId}`);
  },

  /**
   * update
   *
   * update project configuration
   *
   * @param {String} projectId project unique id
   * @param {String} projectConfig project config
   * @returns
   */
  async update(projectId, projectConfig) {
    return requestPut(
      serverAddr.gateway,
      `project/${projectId}`,
      projectConfig,
    );
  },

  /**
   * update features
   *
   * update project features (allowed functions)
   * @param {String} projectId project unique id
   * @param {String} projectFeatures project features
   * @returns
   */
  async updateFeatures(projectId, projectFeatures) {
    return requestPut(
      serverAddr.gateway,
      `project/${projectId}/permissions`,
      projectFeatures,
    );
  },

  /**
   * information
   *
   * get all project information and return or add to vuex store (if !$commit)
   *
   * @param {String} projectId project unique id
   * @param {Boolean} commit indicates whether data should be commited to vuex store
   * @returns if !$commit returns data else null
   */
  async information(projectId, commit = true) {
    const resp = await requestGet(serverAddr.gateway, `project/${projectId}`);

    if (resp != null) {
      if (commit) {
        store.commit('updateProjectData', resp.data);
      } else return resp.data;
    } else {
      if (commit) {
        store.commit('updateProjectData', null);
        this.$store.commit('updateProjectSuccess', false);
      } else return resp.data;
    }
  },

  async publicityStatus(projectId, deviceId, presetId, status) {
    return await requestPut(
      serverAddr.gateway,
      `project/${projectId}/preset/${deviceId}/${presetId}/latestImagePublicityStatus`,
      {isLatestImagePublic: status},
    );
  },

  /**
   * devices
   *
   * get all project related devices and return or add to vuex store (if !$commit)
   *
   * @param {String} projectId project unique id
   * @param {Boolean} commit indicates whether data should be commited to vuex store
   * @returns {null, Object} if !$commit returns data else null
   */
  async devices(projectId) {
    const resp = await requestGet(
      serverAddr.gateway,
      `device/_/project/${projectId}?page=1&page_size=1000`,
    );
    if (resp.success && resp.data != null) {
      return resp.data;
    } else {
      return;
    }
  },

  // ----------------------------------------------------------------------------------------------------------
  // --------------------------------------------- TIMELAPSES -------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------

  /**
   * timelapses
   *
   * get all project related timelapses (only custom timelapses, not day or hour)
   * @param {String} projectId project unique id
   * @returns
   */
  timelapses(projectId) {
    return requestGet(serverAddr.gateway, `indexer/timelapse/${projectId}`);
  },

  /**
   * createTimelapse
   *
   * triggers generation of timelapse in selected preset and time period
   *
   * @param {String} projectId  project unique id
   * @param {String} deviceId device unique id
   * @param {String} presetId preset name
   * @param {Object} payload timelapse information
   * @returns
   */
  createTimelapse(projectId, deviceId, presetId, payload) {
    return requestPost(
      serverAddr.gateway,
      `indexer/timelapse/${projectId}/${deviceId}/${presetId}/assemble`,
      payload,
    );
  },

  /**
   * removeTimelapse
   *
   * remove timelapse in project by id
   *
   * @param {String} projectId project unique id
   * @param {String} timelapseId timelapse id
   * @returns
   */
  removeTimelapse(projectId, timelapseId) {
    return requestDelete(
      serverAddr.gateway,
      `indexer/timelapse/${projectId}/${timelapseId}`,
    );
  },

  /**
   * presetDates
   *
   * get all available dates and hours (as byte value)
   * @todo: implement timezone shift programmatically
   *
   * @param {String} deviceId device unique id
   * @param {*} projectId project unique id
   * @param {*} presetId preset name
   * @returns
   */
  async presetDates(deviceId, projectId, presetId) {
    const resp = await requestGet(
      serverAddr.gateway,
      `indexer/capture/${projectId}/${deviceId}/${presetId}/hours`,
    );
    var datesHandle = resp.data.days.map((date) => {
      return {date: +new Date(date.date), hours: date.hours};
    });

    return datesHandle;
  },

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------- IMAGES ---------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------

  /**
   * latestImages
   *
   * get latest images and return or write to vuex store (if $commit)
   * @param {String} projectId project unique id
   * @param {Boolean} commit indicates whether data should be commited to vuex store
   * @returns {null, Object} if !$commit returns data else null
   */
  async latestImages(projectId, commit = true) {
    const resp = await requestGet(
      serverAddr.gateway,
      `indexer/capture/${projectId}`,
    );

    if (
      resp.success == true &&
      resp.data != null &&
      resp.data.presets != null
    ) {
      if (commit == true)
        return store.commit('updateProjectLatest', resp.data.presets);
      else return resp.data.presets;
    } else {
      if (commit == true) return store.commit('updateProjectLatest', null);
      else return false;
    }
  },

  /**
   * imageByHour
   * get closest image in project, device and preset bucket
   *
   * @param {String} projectId project unique id
   * @param {String} deviceId device unique id
   * @param {String} presetId preset name
   * @param {Date} date date as ISO string
   * @param {Boolean} timelapse indicates if timelapse should be included
   * @returns
   */
  async imageByHour(projectId, deviceId, presetId, date, timelapse = true) {
    return requestGet(
      serverAddr.gateway,
      `indexer/capture/${projectId}/${deviceId}/${presetId}/search?searchDate=${date.toISOString()}&includeTimelapse=${timelapse}`,
    );
  },

  // ----------------------------------------------------------------------------------------------------------
  // ---------------------------------------------- SCHEDULES -------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------

  /**
   * schedules
   *
   * get all project related schedules
   *
   * @param {String} projectId project unique id
   * @param {Boolean} archived boolean if archived should be included
   * @returns
   */
  async schedules(projectId, archived) {
    return requestGet(
      serverAddr.gateway,
      `device-schedule/_/project/${projectId}?page=1&page_size=1000&archived=${
        archived ? 1 : 0
      }`,
    );
  },

  /**
   * addScheduleDevice
   *
   * add a schedule to a device by id
   * @param {String} projectId project unique id
   * @param {String} deviceId device unique id
   * @param {String} scheduleId schedule id
   * @returns
   */
  async addScheduleDevice(projectId, deviceId, scheduleId) {
    return requestPut(
      serverAddr.gateway,
      `device-schedule/${scheduleId}/project/${projectId}/device/${deviceId}`,
      {},
    );
  },

  async updateProjectSchedule(projectId, scheduleId, scheduleObj) {
    return requestPut(
      serverAddr.gateway,
      `device-schedule/${scheduleId}/project/${projectId}`,
      scheduleObj,
    );
  },

  async createProjectSchedule(scheduleObj) {
    return requestPost(serverAddr.gateway, `device-schedule`, scheduleObj);
  },

  /**
   * Get status for schedule Chip button and Banner
   * 
   * @param {*} projectId 
   * @returns 
   */
  async getScheduleStatus(projectId) {
    return requestGet(serverAddr.gateway, `device-schedule/_/project/${projectId}/scheduleStatus`)
  },

  /**
   * deleteSchedule
   *
   * archive project schedule by id
   *
   * @param {String} projectId project unique id
   * @param {String} scheduleId schedule unique id
   * @returns
   */

  async deleteSchedule(projectId, scheduleId) {
    return requestDelete(
      serverAddr.gateway,
      `device-schedule/${scheduleId}/project/${projectId}`,
    );
  },

  // ----------------------------------------------------------------------------------------------------------
  // ------------------------------------------------- USER ---------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------

  /**
   * invite
   *
   * invite user to project by user id and provide user role (editor or viewer)
   * @param {*} projectId project unique id
   * @param {*} userId user unique id
   * @param {*} role user role (editor or viewer)
   * @returns
   */
  async inviteByMail(projectId, email, role) {
    return requestPut(
      serverAddr.gateway,
      `project/${projectId}/user/_/role/${role}?email=${email}`,
    );
  },

  /**
   * invite
   *
   * invite user to project by user id and provide user role (editor or viewer)
   * @param {*} projectId project unique id
   * @param {*} userId user unique id
   * @param {*} role user role (editor or viewer)
   * @returns
   */
  async invite(projectId, userId, role) {
    return requestPut(
      serverAddr.gateway,
      `project/${projectId}/user/${userId}/role/${role}`,
    );
  },

  /**
   * kick
   *
   * remove user from project by user id
   *
   * @param {*} projectId project unqiue id
   * @param {*} userId user unique id
   * @returns
   */
  async kick(projectId, userId) {
    return requestDelete(
      serverAddr.gateway,
      `project/${projectId}/user/${userId}`,
    );
  },

  // ----------------------------------------------------------------------------------------------------------
  // ------------------------------------------------ WEATHER -------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------

    /**
     * weather
     * 
     * get weather data from DWD (via brightsky.dev API) by date, latitude & longitude; returns Object as specified 
     * by Brightsky on https://brightsky.dev/
     * @param {Date} date date as ISO string
     * @param {Float} lat numerical latitude
     * @param {Float} lng numerical longitude
     * @returns {Promise} Request result 
     */
    async weather(date, lat, lng) {
        return axios.get(`https://api.brightsky.dev/weather?date=${date}&lat=${lat}&lon=${lng}`)
    },

    // ----------------------------------------------------------------------------------------------------------
    // ----------------------------------------------- Arming Mask Devices Image --------------------------------
    // ----------------------------------------------------------------------------------------------------------

    /**
     * getArmingMaskDevices 
     * 
     * get the arming mask devices that is used for arming fence image
     * @param {String} projectId project unique id
     */
    async getArmingMaskDevices(projectId) {
        const resp = await requestGet(serverAddr.gateway, `device/_/project/${projectId}/armingPreset`)
        return resp;
    },


        /**
     * fetchProjectDataUsage
     * 
     * Fetch all data usage for the current project id
     * @param {*} projectId project unique id
     */
         async fetchProjectDataUsage(projectId) {
          return requestGet(serverAddr.gateway, `indexer/capture/${projectId}/data-usage`)
      },
   
}