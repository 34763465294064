import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: {
      projects: null,
      devices: null,
      error: false,
      loading: false,
    },

    currentUser: {
      id: null,
      meta: null,
      api: null,
    },

    project: {
      isLoading: true,
      isFailed: false,
      user: null,
      general: null,
      presets: null, 
      latest: null,
      usage: null,
    },



    noDevices: false,
    
    // ========================================================================
    // Get new devices from api to store
    // ========================================================================
    armingMaskDevices: {
      devices: null,
      currentDevice: null,
      dataUsageApi: null,
    },

    // Schedule state
    isShowingScheduleBanner: false,
  },



  mutations: {

    setDataUsage(state, data) {
      state.project.usage = data;
    },



    errorAdmin(state, status) {
      return (state.admin.error = status);
    },
    loadingAdmin(state, status) {
      return (state.admin.loading = status);
    },
    updateAdminProjects(state, projects) {
      return (state.admin.projects = projects);
    },

    updateAdminDevices(state, devices) {
      return (state.admin.devices = devices);
    },

    updateProjectLoading(state, loadingStatus) {
      return (state.project.isLoading = loadingStatus);
    },

    updateProjectSuccess(state, isSuccessfull) {
      return (state.project.isFailed = !isSuccessfull);
    },

    // @MOD
    updateProjectUser(state, userData) {
      state.project.user = userData;
    },

    // @MOD
    updateProjectData(state, projectData) {
      state.project.general = projectData
    },

    // @MOD
    updateProjectLatest(state, latestImages) {
      if (latestImages == null) {
        state.project.latest = null;
        state.project.presets = null;
      } else {
        state.project.latest = {data: (latestImages || []), refreshTime: (+ new Date())}
        state.project.presets = {data: (latestImages || []), refreshTime: (+ new Date())}

      }
    },


    /**
     * destroyProject
     *
     * destroys project object in state
     */
    destroyProject(state) {
      state.project = {
        isLoading: true,
        isFailed: false,
        user: null,
        general: null,
        presets: null, 
        latest: null,
        dataUsageApi: null,
      };
    },

    // ========================================================================
    // Schedule status
    // ========================================================================
    updateShowScheduleBanner(state, payload) {
      state.isShowingScheduleBanner = payload
    },

    bannerNoDevices(state, payload) {
        state.noDevices = payload
    },

    // ------------------------------------------------------------------------------------------------------
    // ------------------------------------------------ USER ------------------------------------------------
    // ------------------------------------------------------------------------------------------------------

    updateCurrentUserToken(state, user) {
      state.currentUser.id = user.id
      state.currentUser.api = user.api
      console.log('Access token', user.api)
    },

    updateCurrentUserMeta(state, userMeta) {
      state.currentUser.meta = userMeta;
    },

    removeCurrentUser(state) {
      return (state.currentUser = {id: null, meta: null, api: null});
    },
    

    // =======================================================================
    // mutation: arming mask 
    // =======================================================================
    updateProjectArmingMaskDevices(state, payload) {
      state.armingMaskDevices.devices = payload
    },
    updateProjectArmingMaskCurrentDevice(state, payload) { 
      state.armingMaskDevices.currentDevice = payload
    },
    
  },



  getters: {


    noDevices(state) {
        return state.noDevices
    },
        armingMaskDevicesData(state) {
            return state.armingMaskDevices.devices
          },
      
          armingMaskCurrentDeviceData(state) { 
            return state.armingMaskDevices.currentDevice
           },

    projectUsage(state) {
      return state.project.usage;
    },
    admin(state) {
      return state.admin;
    },
    projects(state) {
      return state.admin.projects;
    },

    isProjectLoading(state) {
      return state.project.isLoading;
    },

    isProjectLoadingFailed(state) {
      return state.project.isFailed;
    },

    // ---------------------------------------------------------------------------------------
    // --------------------------------- PROJECT-MANAGEMENT ----------------------------------
    // ---------------------------------------------------------------------------------------

    project(state) {
      return state.project;
    },

    projectUsers(state) {
      return state.project.user;
    },

    projectWhitelists(state) {
      return state.project.whitelists;
    },

    latest(state) {
      return state.project.latest;
    },

    currentUser(state) {
      return state.currentUser;
    },
    user(state) {
      return state.currentUser.meta;
    },

    // ========================================================================
    // Schedule status
    // ========================================================================
    getShowScheduleBanner(state) {
      return state.isShowingScheduleBanner;
    },
  },
  actions: {
  },
  modules: {
  }
})
