<template>
  <v-app class="application">
    <v-main style="background-color: #fcfcfc; height: 100vh">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import { EventBus, PROJECT_MANAGEMENT_EVENT } from '@/main.js'

export default {
  name: 'App',
  beforeCreate() {
    document.title = this.$route.meta.tab || this.$product;
  },

  watch: {
    '$route.params.projectId': {
      handler: function (id) {
        if (!id || id == null) this.destroyProject();
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    project() {
      return this.$store.getters.project;
    },
  },

  methods: {
    // ---------------------------------------------------------------------------------------
    // --------------------------------- PROJECT-MANAGEMENT ----------------------------------
    // ---------------------------------------------------------------------------------------

    destroyProject() {
      this.$api.project.destroy();
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  user-drag: none !important;
}

.application {
  background-color: var(--v-background-base);
  -webkit-user-drag: none;
  /* font-family: 'Lato', sans-serif!important; */
  overflow-y: hidden !important;
  user-drag: none !important;
}
</style>

<style lang="scss">


//Chrome, Safari, Opera
// body ::-webkit-scrollbar { width: 0 !important;  }

// //Firefox
// body { overflow: -moz-scrollbars-none; -ms-overflow-style: none;}

// //Internet Explorer, Edge
// body { -ms-overflow-style: none; }

@import './scss/variables.scss';
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden !important;
  font-family: 'Lato';
}
.application {
  // font-family: 'Lato';
  overflow: hidden !important;
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $body-font-family, sans-serif !important;
  }
}
</style>




<style scss>
::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: white;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: #FAFAFA;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #A0A0A0;
    border-radius: 10px;
    margin-right: 5px;
    border: 2px solid #FAFAFA
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #808080;
}
</style>