// import { companyDetails, logo, shareDataUrl, shareShortUrl } from './parkort.config'

// PDF
export const companyAddr = {
  name: 'AlinoTec GmbH & Co. KG',
  streetName: 'Hessenring 22',
  zipCode: '64572',
  city: 'Büttelborn',
  website: 'https://alinotec.de',
};
