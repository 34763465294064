import {i18n} from '@/plugins/i18n';
import router from '../router/index';
import axios from 'axios';
import XLSX from 'xlsx';
import {companyAddr} from '../config';

export default {
  install(Vue) {
    Vue.prototype.$helper = {
      /**
       * imageBase64
       *
       * gets an image from web url and returns base64 image with prefix defined by type
       *
       * @param {string} url image web url
       * @param {string} type base64 URI type (e.g. image/png or application/pdf)
       * @returns {string} base64 image with prefix
       */
      async imageBase64(url, type) {
        try {
          const base64 = await axios
            .get(url, {responseType: 'arraybuffer'})
            .then((response) =>
              Buffer.from(response.data, 'binary').toString('base64'),
            );
          return `data:${type};base64,${base64}`; //Image Base64 Goes here
        } catch {
          return;
        }
      },

      /**
       * openClientProject
       *
       * function to open the correct page of a client project. In case of an arming-only project the arming page is opend
       * @param {Project} project Object of project
       * @returns
       */
      openClientProject(project) {
        if (
          project.allowRecordings == false &&
          project.allowArming == false &&
          project.allowParkControl == true
        ) {
          return router.push({
            name: 'project_gate_report',
            params: {projectId: project.id},
          });
        } else if (project.allowRecordings == true) {
          return router.push({
            name: 'project_dashboard',
            params: {projectId: project.id},
          });
        } else {
          return router.push({
            name: 'project_schedule',
            params: {projectId: project.id},
          });
        }
      },

      /**
       * download
       *
       * downloads a file to web-download folder
       *
       * @param {string} url file web URL
       * @param {string} name desired file name
       * @param {*} type URI type (e.g. image/png)
       * @param {*} fileType file suffix (e.g. .png)
       */
      async download(url, name, type, fileType) {


        // var base64 = await axios
        //   .get('https://storage.googleapis.com/storage.alinocam.com/c6e7481a-4302-4cc3-9c17-9cf00c1c1e1c/1536/alinocam_04/timelapses/4cc90031-01d5-44b7-8da1-e3b00876f6f4.mp4?GoogleAccessId=465837658632-compute%40developer.gserviceaccount.com&Expires=1674639121&Signature=UbVWXmxMbDxVXDxlNw7FywUViNBeh%2F06iZ9fz6NozhTQfgbMTjgVAO7NLS7XiSfAisxXfP1K%2BqIXJgtsuml8NI%2B16aWFwSpmLC%2F5aAZbkv9OJ7JwOLWKcqFGx2nXqiHF3stgSmqKrhyMG6nRof9B8c0q7h28ZV3YfSBNxXRchMshFbc3%2BtPZDEbNuamEig%2F7ybInUGSN8pWnNBIr6bGsyOlWfX2UJRznD5lJM7zGcju0euMcERn6%2FrReZF3%2BCqYy6Cusfnyv5C8ieJ6xKFDBtKe1bIdq%2BalOoto3ZhkAqwGRyaA1db%2BPhhv13cGjxpBYz7P7%2F1iOwp0Bo4rj3TWM%2BA%3D%3D', {responseType: 'arraybuffer'})
        //   .then((response) =>
        //     Buffer.from(response.data, 'binary').toString('base64'),
        //   );
        const a = document.createElement('a'); //Create <a>
        a.download = `${name}.${fileType}`; //File name Here
        a.href = `${url}&response-content-disposition=attachment;`//`data:${type}/${fileType};base64, ${base64}`; //Image Base64 Goes here
      
      
        document.body.appendChild(a);
a.target = '_self'
        a.click(); //Downloaded file
        document.body.removeChild(a)
      },

      /**
       * downloadJSON
       *
       * converts an Object to a downloadable file
       *
       * @param {Object} data JS object data to be downloaded as JSON file
       * @param {string} filename desired file name
       */
      async downloadJSON(data, filename) {
        // Creating a blob object from non-blob data using the Blob constructor
        const blob = new Blob([JSON.stringify(data)], {
          type: 'application/json',
        });
        const url = URL.createObjectURL(blob);
        // Create a new anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.json`;
        a.click();
        a.remove();
      },

      /**
       * formatID
       *
       * preprends 0s to number for equal length IDs
       * @param {number} id ID number
       * @returns {string} formatted ID
       */
      formatID(id) {
        return `000000${id}`.slice(-6);
      },

      /**
       * isValidIp
       *
       * validation (regex) of IPv4 Address
       * @param {string} ipAddr IPv4 address
       * @returns {boolean} valid IPv4 (true)
       */
      isValidIp(ipAddr) {
        const regexExp =
          /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        return regexExp.test(ipAddr); // true
      },

      /**
       * isValidMail
       *
       * regex validation of mail
       * @param {string} mail Mail-Adress
       * @returns valid Mail (true)
       */
      isValidMail(mail) {
        if (!mail || mail == null) return false;
        const mailRegularExpr =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return !!mail.match(mailRegularExpr);
      },

      /**
       * parsePingStatus
       * @param {number} dateHandle date as unix timestamp
       * @param {*} status
       * @returns
       */
      parsePingStatus(dateHandle, status) {
        if (dateHandle == null) return null;

        if (status != 1) return 0;

        var seconds = Math.floor((new Date() - new Date(dateHandle)) / 1000);

        var interval = seconds / 31536000 / 2592000 / 86400 / 3600;
        if (interval > 1) {
          return 0;
        }

        interval = seconds / 60;
        if (interval > 55) {
          return 1;
        }
        return 2;
      },

      /**
       * formatLastHours
       *
       * returns time difference to current time
       *
       * @param {number} dateHandle date handle (unixtime)
       * @param {boolean} detailed detailed time (seconds)
       * @returns
       */
      formatLastHours(dateHandle, detailed = false) {
        if (dateHandle == null) return 'noch nie gesehen';
        var seconds = Math.floor((new Date() - new Date(dateHandle)) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
          return 'vor ' + Math.floor(interval) + ' Jahr(en)';
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return 'vor ' + Math.floor(interval) + ' Monat(en)';
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return 'vor ' + Math.floor(interval) + ' Tag(en)';
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return 'vor ' + Math.floor(interval) + ' Stunde(n)';
        }
        interval = seconds / 60;
        if (interval > 1) {
          return 'vor ' + Math.floor(interval) + ' Minute(n)';
        }
        if (detailed) {
          return interval;
        } else {
          return 'vor ' + `0${seconds}`.slice(-2) + ' Sekunden';
        }
      },

      /**
       *
       * @param {number} deviceType device type as number
       * @returns {string} device type as String
       */
      formatDeviceType(deviceType) {
        switch (deviceType) {
          case 1:
            return 'NVR';
          case 2:
            return 'PTZ';
          case 3:
            return 'ANPR';
          default:
        }
      },

      forward(routeName) {
        if (!routeName || router.currentRoute.name == routeName) return;
        return router.push({name: routeName});
      },

      shortenText(str, n, useWordBoundary = false) {
        // console.l(str)
        if (str.length <= n) {
          return str;
        }
        const subString = str.substring(0, n - 1);
        return (
          (useWordBoundary
            ? subString.substring(0, subString.lastIndexOf(' '))
            : subString) + '…'
        );
      },

      formatPresetName(presetName) {
        return presetName.replace('alinocam_', '');
      },

      formatCredentialName(secretName) {
        var splitName = secretName.split('/');
        return splitName[splitName.length - 1];
      },

      openTab(url) {
        var popupHandle = window.open(url, '_blank');

        if (popupHandle == null || typeof popupHandle == 'undefined') {
          Vue.prototype.$toast.error(i18n.t('helper.toasts.error.popUpBlock'));
        } else {
          popupHandle.focus();
        }
      },

      formatDateRange(start, end) {
        var startDate = this.formatDate(start, false, false);

        if (new Date(start).getFullYear() === new Date(end).getFullYear()) {
          startDate = startDate.replace(
            String(new Date(start).getFullYear()),
            '',
          );

          // if ((new Date(start).getMonth()) === (new Date(end).getMonth())) {

          // }
        }
        var endDate = this.formatDate(end, false, false);

        return `${startDate} - ${endDate}`;
      },

      formatDate(dateObj, weekday = true, time = true) {
        const event = new Date(dateObj);

        var options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };

        if (time == true)
          options = {...options, hour: '2-digit', minute: '2-digit'};
        if (weekday == true) options = {...options, weekday: 'short'};

        if (time == true) {
          return `${event.toLocaleTimeString(
            `${i18n.locale}-${i18n.locale}`,
            options,
          )} ${i18n.locale == 'de' ? 'Uhr' : ''}`;
        } else {
          return event.toLocaleDateString(
            `${i18n.locale}-${i18n.locale}`,
            options,
          );
        }
      },
      _clipboardFallback(textString) {
        var textArea = document.createElement('textarea');
        textArea.value = textString;
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      },

      copy2Clipboard(textString, typeName) {
        if (!navigator.clipboard) {
          return this._clipboardFallback(textString);
        }
        navigator.clipboard.writeText(textString).then(
          function () {
            // console.l("COPIED!")

            Vue.prototype.$toast.info(
              i18n.t('helper.toasts.info.copied', {
                type: typeName == null ? '' : typeName,
                text: Vue.prototype.$helper.shortenText(textString, 35),
              }),
            );
          },
          function (err) {
            console.error('Async: Could not copy text: ', err);
          },
        );
      },

      formatByteSize(bytes) {
        if (!bytes) return '-';
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';

        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
      },

      formatDuration(duration) {
        var hours = Math.floor(Math.abs(duration) / 60);
        var minutes = `0${Math.floor(Math.abs(duration)) % 60}`.slice(-2);

        if (hours < 100) hours = `0${hours}`.slice(-2);

        return `${hours}:${minutes}`;
      },

      countryName(countryCode) {
        if (countryCode == null || countryCode.length > 2) return;
        let regionNames = new Intl.DisplayNames([i18n.locale], {
          type: 'region',
        });
        return regionNames.of(countryCode.toUpperCase()); // "United States"
      },

      projectStatus(project, devices) {
        if (devices == null || devices.length == 0) return 0; // nothing

        if (
          devices.some((device) => device.status == 3) ||
          (project.schedule != null &&
            project.schedule.scheduleEntries != null &&
            project.schedule.scheduleEntries.some((entry) => entry.status == 2))
        )
          return 2;

        if (
          project.schedule != null &&
          project.schedule.scheduleEntries != null
        ) {
          var recorderList = devices
            .filter((device) => device.type == 1)
            .map((device) => {
              return {
                ...device,
                entry: project.schedule.scheduleEntries.reduce((a, b) =>
                  a.deviceId == device.id ? a : b,
                ),
              };
            });

          if (recorderList == null || recorderList.length == 0) {
            recorderList = devices.map((device) => {
              return {
                ...device,
                entry: project.schedule.scheduleEntries.reduce((a, b) =>
                  a.deviceId == device.id ? a : b,
                ),
              };
            });
            if (
              recorderList.some((device) =>
                device.customDeviceName.startsWith('TURM'),
              )
            )
              return 4;
          }

          if (
            recorderList.some((device) => device.status == 3) ||
            recorderList.some((device) => device.entry.status == 2)
          )
            return 2; // synchronizing
          if (recorderList.some((device) => device.entry.status != 1)) return 4; // schedule error
          if (!recorderList.some((device) => device.entry.status != 1))
            return 3; // success

          return 1;
        }
      },

      async downloadXLSX(title, groupByValue, headers, data) {
        try {
          var wb = XLSX.utils.book_new();

          // define workbook metadata (props)
          wb.Props = {
            Title: title,
            Author: companyAddr.name,
            CreatedDate: new Date(),
          };

          if (groupByValue == null || groupByValue.length == 0) {
            // create new sheet
            wb.SheetNames.push(title);

            // filter data by selected header values
            var tableDataHandle = [headers.map(({text}) => text)];

            data.forEach((item) => {
              var itemDataHandle = [];
              headers.forEach((header) => {
                if (item[header.value] != null) {
                  var rowValue = item[header.value];
                  if (header.date == true) {
                    // console.log(rowValue)
                    rowValue = this.formatDate(rowValue, false, true);
                  }
                  itemDataHandle.push(rowValue);
                } else {
                  itemDataHandle.push(undefined);
                }
              });
              tableDataHandle.push(itemDataHandle);
            });

            // throw error in case no data was found; download button is generally disabled if no data
            if (tableDataHandle == null || tableDataHandle.length == 0)
              throw new Error();

            // add data to worksheet
            // console.log(tableDataHandle, headers.map(header => {return{wch: ((header.width != null) ? header.width : 200)}}))

            const worksheet = XLSX.utils.aoa_to_sheet(tableDataHandle);
            worksheet['!cols'] = headers.map((header) => {
              return {wch: header.width != null ? header.width : 10};
            });

            wb.Sheets[title] = worksheet;
          }

          // download file
          XLSX.writeFile(wb, `${title}_${+new Date()}.xlsx`);
        } catch (err) {
          // console.log(err)
          Vue.prototype.$toast.error(
            i18n.t('helper.toasts.error.errorXLSXDownload'),
          );
        }
      },
    };
  },
};
